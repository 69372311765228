import { combineReducers } from 'redux';
import { createFlagsReducer } from 'flag';
import { reducer as toastrReducer } from 'react-redux-toastr';

import flags from '../flags';
import mySmeltersReducer from './mySmeltersReducer';
import myFavSmeltersReducer from './myFavSmeltersReducer';
import niNewestDateReducer from './ni/newestDateReducer';
import niPseudoGroupReducer from './ni/pseudoGroupReducer';
import niSmelterAllMapDataReducer from './ni/smelterAllMapDataReducer';
import niSmelterAllTimeBySmelterDataReducer from './ni/smelterAllTimeBySmelterDataReducer';
import niSmelterDataFreeReducer from './ni/smelterDataFreeReducer';
import niSmelterDataReducer from './ni/smelterDataReducer';
import niSmelterListReducer from './ni/smelterListReducer';
import niSmelterMapDataReducer from './ni/smelterMapDataReducer';
import niSmelterRecentDataReducer from './ni/smelterRecentDataReducer';
import niSmelterRecentUpdatesDataReducer from './ni/smelterRecentUpdatesDataReducer';
import niSmelterRegionsReducer from './ni/smelterRegionsReducer';
import niSmelterUpdatesDataReducer from './ni/smelterUpdatesDataReducer';
import niSelectedSmeltersReducer from './ni/selectedSmeltersReducer';
import niSmelterCapacityReducer from './ni/smelterCapacityReducer';
import niProductionReducer from './ni/productionReducer';
import niPseudoGroupSmeltersReducer from './ni/pseudoGroupSmeltersReducer';
import niProductionSmeltersReducer from './ni/productionSmeltersReducer';
import niSelectedProductionSmeltersReducer from './ni/selectedProductionSmeltersReducer';
import newestDateReducer from './newestDateReducer';
import pseudoGroupReducer from './pseudoGroupReducer';
import smelterAllMapDataReducer from './smelterAllMapDataReducer';
import smelterAllTimeBySmelterDataReducer from './smelterAllTimeBySmelterDataReducer';
import smelterDataFreeReducer from './smelterDataFreeReducer';
import smelterDataReducer from './smelterDataReducer';
import smelterListReducer from './smelterListReducer';
import smelterMapDataReducer from './smelterMapDataReducer';
import smelterRecentDataReducer from './smelterRecentDataReducer';
import smelterRecentUpdatesDataReducer from './smelterRecentUpdatesDataReducer';
import smelterRegionsReducer from './smelterRegionsReducer';
import smelterUpdatesDataReducer from './smelterUpdatesDataReducer';
import smelterShrinkAllTimeMapDataReducer from './smelterShrinkAllTimeMapDataReducer';
import selectedSmeltersReducer from './selectedSmeltersReducer';
import smelterCapacityReducer from './smelterCapacityReducer';
import productionReducer from './productionReducer';
import pseudoGroupSmeltersReducer from './pseudoGroupSmeltersReducer';
import productionSmeltersReducer from './productionSmeltersReducer';
import selectedProductionSmeltersReducer from './selectedProductionSmeltersReducer';

export default combineReducers({
	flags: createFlagsReducer(flags),
	mySmelters: mySmeltersReducer,
	myFavSmelters: myFavSmeltersReducer,
	niSelectedSmelters: niSelectedSmeltersReducer,
	niNewestDate: niNewestDateReducer,
	niPseudoGroups: niPseudoGroupReducer,
	niSmelterAllMapData: niSmelterAllMapDataReducer,
	niSmelterAllTimeBySmelter: niSmelterAllTimeBySmelterDataReducer,
	niSmelterData: niSmelterDataReducer,
	niSmelterDataFree: niSmelterDataFreeReducer,
	niSmelterMapData: niSmelterMapDataReducer,
	niSmelterList: niSmelterListReducer,
	niSmelterRecent: niSmelterRecentDataReducer,
	niSmelterRecentUpdates: niSmelterRecentUpdatesDataReducer,
	niSmelterRegions: niSmelterRegionsReducer,
	niSmelterCapacity: niSmelterCapacityReducer,
	niSmelterUpdates: niSmelterUpdatesDataReducer,
	niProduction: niProductionReducer,
	niPseudoGroupSmelters: niPseudoGroupSmeltersReducer,
	niProductionSmelters: niProductionSmeltersReducer,
	niSelectedProdSmelters: niSelectedProductionSmeltersReducer,
	selectedSmelters: selectedSmeltersReducer,
	newestDate: newestDateReducer,
	pseudoGroups: pseudoGroupReducer,
	smelterAllMapData: smelterAllMapDataReducer,
	smelterAllTimeBySmelter: smelterAllTimeBySmelterDataReducer,
	smelterShrinkedAllTimeData: smelterShrinkAllTimeMapDataReducer,
	smelterData: smelterDataReducer,
	smelterDataFree: smelterDataFreeReducer,
	smelterMapData: smelterMapDataReducer,
	smelterList: smelterListReducer,
	smelterRecent: smelterRecentDataReducer,
	smelterRecentUpdates: smelterRecentUpdatesDataReducer,
	smelterRegions: smelterRegionsReducer,
	smelterUpdates: smelterUpdatesDataReducer,
	smelterCapacity: smelterCapacityReducer,
	production: productionReducer,
	pseudoGroupSmelters: pseudoGroupSmeltersReducer,
	productionSmelters: productionSmeltersReducer,
	selectedProdSmelters: selectedProductionSmeltersReducer,
	toastr: toastrReducer
});
