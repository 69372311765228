export const ADD_MY_FAV_SMELTERS = 'add_my_fav_smelters';
export const FETCH_MY_FAV_SMELTERS = 'fetch_my_fav_smelters';
export const REMOVE_MY_FAV_SMELTERS = 'remove_my_fav_smelters';
export const RESET_MY_FAV_SMELTERS_ERR = 'reset_my_fav_smelters_err';
export const UPDATE_MY_FAV_SMELTERS_ERR = 'update_my_fav_smelters_err';

export const FETCH_MYSMELTERS = 'fetch_mysmelters';
export const FETCH_MYSMELTERS_ERR = 'fetch_mysmelters_err';

export const SHRINK_SMELTERS_ALL_TIME_MAP_DATA =
	'shrink_smelters_all_time_map_data';

//ni
export const FETCH_NI_NEWEST_DATE = 'fetch_ni_newest_date';
export const FETCH_NI_NEWEST_DATE_ERR = 'fetch_ni_newest_date_err';

export const FETCH_NI_PSEUDOGROUPS = 'fetch_ni_pseudogroups';
export const FETCH_NI_PSEUDOGROUPS_ERR = 'fetch_ni_pseudogroups_err';

export const FETCH_NI_SMELTER_ALL_MAP_DATA = 'fetch_ni_smelter_all_map_data';
export const FETCH_NI_SMELTER_ALL_MAP_DATA_ERR =
	'fetch_ni_smelter_all_map_data_err';

export const FETCH_NI_SMELTER_ALL_TIME_BY_SMELTER_DATA =
	'fetch_ni_smelter_all_time_by_smelter_data';
export const FETCH_NI_SMELTER_ALL_TIME_BY_SMELTER_DATA_ERR =
	'fetch_ni_smelter_all_time_by_smelter_data_err';

export const FETCH_NI_SMELTER_DATA = 'fetch_ni_smelter_data';
export const FETCH_NI_SMELTER_DATA_ERR = 'fetch_ni_smelter_data_err';

export const FETCH_NI_SMELTER_DATA_FREE = 'fetch_ni_smelter_data_free';
export const FETCH_NI_SMELTER_DATA_FREE_ERR = 'fetch_ni_smelter_data_free_err';

export const FETCH_NI_SMELTER_LIST = 'fetch_ni_smelter_list';
export const FETCH_NI_SMELTER_LIST_ERR = 'fetch_ni_smelter_list_err';

export const FETCH_NI_SMELTER_MAP_DATA = 'fetch_ni_smelter_map_data';
export const FETCH_NI_SMELTER_MAP_DATA_ERR = 'fetch_ni_smelter_map_data_err';

export const FETCH_NI_SMELTER_RECENT = 'fetch_ni_smelter_recent';
export const FETCH_NI_SMELTER_RECENT_ERR = 'fetch_ni_smelter_recent_err';

export const FETCH_NI_SMELTER_RECENT_UPDATES =
	'fetch_ni_smelter_recent_updates';
export const FETCH_NI_SMELTER_RECENT_UPDATES_ERR =
	'fetch_ni_smelter_recent_updates_err';

export const FETCH_NI_SMELTER_REGIONS = 'fetch_ni_smelter_regions';
export const FETCH_NI_SMELTER_REGIONS_ERR = 'fetch_ni_smelter_regions_err';

export const FETCH_NI_SMELTER_UPDATES = 'fetch_ni_smelter_updates';
export const FETCH_NI_SMELTER_UPDATES_ERR = 'fetch_ni_smelter_updates_err';

export const FETCH_NI_SMELTER_CAPACITY = 'fetch_ni_smelter_capacity';
export const FETCH_NI_SMELTER_CAPACITY_ERR = 'fetch_ni_smelter_capacity_err';

export const FETCH_NI_PRODUCTION_DATA = 'fetch_ni_production_data';
export const FETCH_NI_PRODUCTION_DATA_ERR = 'fetch_ni_production_data_err';
export const RESET_NI_PRODUCTION_DATA = 'reset_ni_production_data';

export const FETCH_NI_PSEUDOGROUP_SMELTERS = 'fetch_ni_pseudogroup_smelters';
export const FETCH_NI_PSEUDOGROUP_SMELTERS_ERR =
	'fetch_ni_pseudogroup_smelters_err';

export const FETCH_NI_PRODUCTION_SMELTERS_DATA =
	'fetch_ni_production_smelters_data';
export const FETCH_NI_PRODUCTION_SMELTERS_DATA_ERR =
	'fetch_ni_production_smelters_data_err';

export const SELECT_NI_PROD_SMELTER = 'select_ni_prod_smelter';
export const UNSELECT_NI_PROD_SMELTER = 'unselect_ni_prod_smelter';
export const RESET_NI_PROD_SMELTERS = 'reset_ni_prod_smelters';

export const RESET_NI_NEWEST_DATE = 'reset_ni_newest_date';
export const RESET_NI_SMELTER_MAP_DATA = 'reset_smelter_map_data';

export const SELECT_NI_SMELTER = 'select_ni_smelter';
export const UNSELECT_NI_SMELTER = 'unselect_ni_smelter';

//cu
export const FETCH_NEWEST_DATE = 'fetch_newest_date';
export const FETCH_NEWEST_DATE_ERR = 'fetch_newest_date_err';

export const FETCH_PSEUDOGROUPS = 'fetch_pseudogroups';
export const FETCH_PSEUDOGROUPS_ERR = 'fetch_pseudogroups_err';

export const FETCH_SMELTER_ALL_MAP_DATA = 'fetch_smelter_all_map_data';
export const FETCH_SMELTER_ALL_MAP_DATA_ERR = 'fetch_smelter_all_map_data_err';

export const FETCH_SMELTER_ALL_TIME_BY_SMELTER_DATA =
	'fetch_smelter_all_time_by_smelter_data';
export const FETCH_SMELTER_ALL_TIME_BY_SMELTER_DATA_ERR =
	'fetch_smelter_all_time_by_smelter_data_err';

export const FETCH_SMELTER_DATA = 'fetch_smelter_data';
export const FETCH_SMELTER_DATA_ERR = 'fetch_smelter_data_err';

export const FETCH_SMELTER_DATA_FREE = 'fetch_smelter_data_free';
export const FETCH_SMELTER_DATA_FREE_ERR = 'fetch_smelter_data_free_err';

export const FETCH_SMELTER_LIST = 'fetch_smelter_list';
export const FETCH_SMELTER_LIST_ERR = 'fetch_smelter_list_err';

export const FETCH_SMELTER_MAP_DATA = 'fetch_smelter_map_data';
export const FETCH_SMELTER_MAP_DATA_ERR = 'fetch_smelter_map_data_err';

export const FETCH_SMELTER_RECENT = 'fetch_smelter_recent';
export const FETCH_SMELTER_RECENT_ERR = 'fetch_smelter_recent_err';

export const FETCH_SMELTER_RECENT_UPDATES = 'fetch_smelter_recent_updates';
export const FETCH_SMELTER_RECENT_UPDATES_ERR =
	'fetch_smelter_recent_updates_err';

export const FETCH_SMELTER_CAPACITY = 'fetch_smelter_capacity';
export const FETCH_SMELTER_CAPACITY_ERR = 'fetch_smelter_capacity_err';

export const FETCH_SMELTER_REGIONS = 'fetch_smelter_regions';
export const FETCH_SMELTER_REGIONS_ERR = 'fetch_smelter_regions_err';

export const FETCH_SMELTER_UPDATES = 'fetch_smelter_updates';
export const FETCH_SMELTER_UPDATES_ERR = 'fetch_smelter_updates_err';

export const RESET_NEWEST_DATE = 'reset_newest_date';
export const RESET_SMELTER_MAP_DATA = 'reset_smelter_map_data';

export const SELECT_SMELTER = 'select_smelter';
export const UNSELECT_SMELTER = 'unselect_smelter';

export const FETCH_PRODUCTION_DATA = 'fetch_production_data';
export const FETCH_PRODUCTION_DATA_ERR = 'fetch_production_data_err';
export const RESET_PRODUCTION_DATA = 'reset_production_data';

export const FETCH_PSEUDOGROUP_SMELTERS = 'fetch_pseudogroup_smelters';
export const FETCH_PSEUDOGROUP_SMELTERS_ERR = 'fetch_pseudogroup_smelters_err';

export const FETCH_PRODUCTION_SMELTERS_DATA = 'fetch_production_smelters_data';
export const FETCH_PRODUCTION_SMELTERS_DATA_ERR =
	'fetch_production_smelters_data_err';

export const SELECT_PROD_SMELTER = 'select_prod_smelter';
export const UNSELECT_PROD_SMELTER = 'unselect_prod_smelter';
export const RESET_PROD_SMELTERS = 'reset_prod_smelters';
