import React, { Component } from 'react';
import {
	Alert,
	Button,
	Card,
	CardBody,
	CardHeader,
	Col,
	Container,
	Form,
	FormGroup,
	FormText,
	Input,
	InputGroup,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
	Table
} from 'reactstrap';

import { Auth } from 'aws-amplify';
import BodyClassName from 'react-body-classname';
import DocumentTitle from 'react-document-title';
import ReactSVG from 'react-svg';
import ReactGA from 'react-ga';

import {
	getAuthenticatedUserGroups,
	getAuthenticatedUserAttributes
} from 'actions';
import { cognitoError, LoaderButton, Terms } from 'components';

import './SignUp.scss';

import earthiLogo from 'images/earthi.svg';
import savantLogo from 'images/savant.svg';

export default class SignUp extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			username: '',
			givenName: '',
			familyName: '',
			organisation: '',
			email: '',
			password: '',
			confirmPassword: '',
			intendedGroup: 'savant-free',
			acceptTerms: false,
			confirmationCode: '',
			newUser: null,
			errorMsg: '',
			termsModal: false,
			groupsModal: false
		};

		this.toggleTermsModal = this.toggleTermsModal.bind(this);
		this.toggleGroupsModal = this.toggleGroupsModal.bind(this);
	}

	componentDidMount() {
		ReactGA.pageview('/signUp', null, 'Sign Up');
	}

	toggleTermsModal() {
		this.setState({
			termsModal: !this.state.termsModal
		});
	}

	toggleGroupsModal() {
		this.setState({
			groupsModal: !this.state.groupsModal
		});
	}

	validateForm() {
		const {
			username,
			givenName,
			familyName,
			email,
			organisation,
			password,
			confirmPassword,
			acceptTerms
		} = this.state;

		return (
			username.length > 0 &&
			givenName.length > 0 &&
			familyName.length > 0 &&
			email.length > 0 &&
			organisation.length > 0 &&
			password.length > 0 &&
			password === confirmPassword &&
			acceptTerms
		);
	}

	validateConfirmationForm() {
		const { confirmationCode } = this.state;
		return confirmationCode.length > 0;
	}

	handleChange = (event) => {
		this.setState({
			[event.target.id]: event.target.value,
			errorMsg: ''
		});
	};

	handleCheckboxChange = (event) => {
		this.setState({
			[event.target.id]: event.target.checked,
			errorMsg: ''
		});
	};

	handleSubmit = async (event) => {
		event.preventDefault();

		const {
			username,
			password,
			givenName: given_name,
			familyName: family_name,
			email,
			organisation,
			acceptTerms,
			intendedGroup
		} = this.state;

		this.setState({
			isLoading: true
		});

		try {
			const newUser = await Auth.signUp({
				username,
				password,
				attributes: {
					given_name,
					family_name,
					email,
					'custom:organisation': organisation,
					'custom:acceptTerms': acceptTerms.toString(),
					'custom:intendedGroup': intendedGroup
				}
			});
			this.setState({
				newUser,
				isLoading: false
			});
		} catch (err) {
			this.setState({
				isLoading: false,
				errorMsg: cognitoError(err)
			});
		}
	};

	handleConfirmationSubmit = async (event) => {
		event.preventDefault();

		this.setState({
			isLoading: true
		});
		try {
			await Auth.confirmSignUp(
				this.state.username,
				this.state.confirmationCode
			);
			window.setTimeout(async () => {
				await Auth.signIn(this.state.username, this.state.password);
				const { username } = await getAuthenticatedUserAttributes();
				const { authenticatedUserGroups: authGroups } =
					await getAuthenticatedUserGroups();
				ReactGA.event({
					category: 'authorisation',
					action: 'signup',
					label: username
				});
				this.props.userHasAuthenticated(true, authGroups);
				this.props.history.push('/home');
			}, 1000);
		} catch (err) {
			this.setState({
				isLoading: false,
				errorMsg: cognitoError(err)
			});
		}
	};

	renderConfirmationForm() {
		const { confirmationCode, errorMsg, isLoading } = this.state;

		return (
			<Form onSubmit={this.handleConfirmationSubmit}>
				<FormGroup>
					<Label for="confirmationCode">Verification code</Label>
					<Input
						id="confirmationCode"
						placeholder="Enter verification code"
						autoFocus
						value={confirmationCode}
						onChange={this.handleChange}
					/>
					<FormText color="muted">
						Please check your email for the verification code.
					</FormText>
				</FormGroup>
				{errorMsg && (
					<Alert color="warning" className="py-2 px-3">
						{errorMsg}
					</Alert>
				)}
				<LoaderButton
					block
					color="primary"
					disabled={!this.validateConfirmationForm()}
					type="submit"
					isLoading={isLoading}
					text="Verify"
					loadingText="Verifying…"
				/>
			</Form>
		);
	}

	buildTermsModal() {
		const { termsModal } = this.state;

		return (
			<Modal isOpen={termsModal} toggle={this.toggleTermsModal} fade size="lg">
				<ModalHeader toggle={this.toggleTermsModal}>
					Terms and Conditions for the Use of the SAVANT Service
					<br />
					<span className="smaller">Last updated 31st January 2024</span>
				</ModalHeader>
				<ModalBody className="small signup-modal">
					<Terms />
				</ModalBody>
				<ModalFooter>
					<Button color="secondary" onClick={this.toggleTermsModal}>
						Close
					</Button>
				</ModalFooter>
			</Modal>
		);
	}

	buildGroupsModal() {
		const { groupsModal } = this.state;

		return (
			<Modal
				isOpen={groupsModal}
				toggle={this.toggleGroupsModal}
				fade
				size="lg"
			>
				<ModalHeader toggle={this.toggleGroupsModal}>
					Account Packages
				</ModalHeader>
				<ModalBody className="small signup-modal">
					SAVANT accounts are offered at the following levels. Please indicate
					your level of interest.
					<Table className="mt-3 mb-0">
						<thead>
							<tr>
								<th>Package name</th>
								<th>Updates</th>
								<th>Delay</th>
								<th>Indexes</th>
								<th>Smelters</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<th scope="row">Free</th>
								<td>Daily</td>
								<td>2 weeks</td>
								<td>
									<ul className="pl-3 mb-0">
										<li>Global</li>
									</ul>
								</td>
								<td>None</td>
							</tr>
							<tr>
								<th scope="row">Business</th>
								<td>Daily</td>
								<td>None</td>
								<td>
									<ul className="pl-3 mb-0">
										<li>Global</li>
										<li>China</li>
										<li>Continent-based regions</li>
										<li>Countries</li>
										<li>Chinese regions</li>
									</ul>
								</td>
								<td>None</td>
							</tr>
							<tr>
								<th scope="row">Enterpise</th>
								<td>Daily</td>
								<td>None</td>
								<td>
									<ul className="pl-3 mb-0">
										<li>Global</li>
										<li>China</li>
										<li>Continent-based regions</li>
										<li>Countries</li>
										<li>Chinese regions</li>
									</ul>
								</td>
								<td>
									All smelters provided, including
									<br />
									individual smelter plots and
									<br />
									KML files to visualise the site.
								</td>
							</tr>
						</tbody>
					</Table>
				</ModalBody>
				<ModalFooter>
					<Button color="secondary" onClick={this.toggleGroupsModal}>
						Close
					</Button>
				</ModalFooter>
			</Modal>
		);
	}

	renderForm() {
		const {
			username,
			password,
			confirmPassword,
			givenName,
			familyName,
			email,
			intendedGroup,
			organisation,
			acceptTerms,
			isLoading,
			errorMsg
		} = this.state;

		return (
			<Form onSubmit={this.handleSubmit}>
				<p>
					To access the SAVANT application please fill in your details. All
					fields are required.
				</p>
				<FormGroup>
					<Label for="username">Username</Label>
					<span className="help-block small font-italic text-muted ml-3">
						Username should not include spaces
					</span>
					<Input
						id="username"
						placeholder="Enter username"
						autoFocus
						value={username}
						onChange={this.handleChange}
					/>
				</FormGroup>

				<Row>
					<Col>
						<FormGroup>
							<Label for="givenName">First name</Label>
							<Input
								id="givenName"
								placeholder="Enter first name"
								value={givenName}
								onChange={this.handleChange}
							/>
						</FormGroup>
					</Col>
					<Col>
						<FormGroup>
							<Label for="familyName">Last name</Label>
							<Input
								id="familyName"
								placeholder="Enter last name"
								value={familyName}
								onChange={this.handleChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<FormGroup>
					<Label for="organisation">Organisation</Label>
					<Input
						id="organisation"
						placeholder="Enter organisation"
						value={organisation}
						onChange={this.handleChange}
					/>
				</FormGroup>
				<FormGroup>
					<Label for="email">Email</Label>
					<Input
						id="email"
						placeholder="Enter email"
						value={email}
						onChange={this.handleChange}
					/>
				</FormGroup>
				<Row>
					<Col>
						<FormGroup>
							<Label for="password">Password</Label>
							<Input
								id="password"
								type="password"
								placeholder="Enter password"
								value={password}
								onChange={this.handleChange}
							/>
						</FormGroup>
					</Col>
					<Col>
						<FormGroup>
							<Label for="confirmPassword">Confirm password</Label>
							<Input
								id="confirmPassword"
								type="password"
								placeholder="Enter password confirmation"
								value={confirmPassword}
								onChange={this.handleChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<p className="help-block small text-muted font-italic">
					A password must be more than 7 characters and include an uppercase
					letter, a lowercase letter, a special character and a number
				</p>
				<FormGroup>
					<Label for="intendedGroup">Preferred Account Package</Label>{' '}
					<span className="ml-2 text-muted small">
						Sign up gives immediate Free access
					</span>
					<InputGroup>
						<Input
							type="select"
							id="intendedGroup"
							value={intendedGroup}
							onChange={this.handleChange}
						>
							<option value="savant-free">Free</option>
							<option value="savant-business">Business</option>
							<option value="savant-enterprise">Enterprise</option>
						</Input>
						<Button
							color="secondary"
							outline
							onClick={this.toggleGroupsModal}
							className="ml-2"
						>
							<i className="fa fa-question fa-lg" />
						</Button>
					</InputGroup>
				</FormGroup>
				<FormGroup check className="mb-3">
					<Label for="acceptTerms" className="sr-only">
						Accept terms
					</Label>
					<Input
						id="acceptTerms"
						type="checkbox"
						value={acceptTerms}
						onChange={this.handleCheckboxChange}
					/>
					I have read and agree to the{' '}
					<Button
						color="link"
						id="termsButton"
						className="p-0 align-baseline"
						onClick={this.toggleTermsModal}
					>
						Terms and Conditions
					</Button>
				</FormGroup>

				{this.buildTermsModal()}
				{this.buildGroupsModal()}

				{errorMsg && (
					<Alert color="warning" className="py-2 px-3">
						{errorMsg}
					</Alert>
				)}
				<LoaderButton
					block
					color="primary"
					disabled={!this.validateForm()}
					type="submit"
					isLoading={isLoading}
					text="Sign up"
					loadingText="Signing up…"
				/>
			</Form>
		);
	}

	render() {
		const { newUser } = this.state;

		return (
			<DocumentTitle title="SAVANT | Sign up">
				<BodyClassName className="bg-dark">
					<Container fluid>
						<Card className="card-register mx-auto my-5">
							<CardHeader className="card-logo-header">
								<ReactSVG
									src={earthiLogo}
									className="card-logo"
									svgClassName="earthi-card-logo"
								/>
								<ReactSVG
									src={savantLogo}
									className="card-logo"
									svgClassName="savant-card-logo"
								/>
								<h1 className="sr-only">SAVANT</h1>
							</CardHeader>
							<CardBody>
								{newUser === null
									? this.renderForm()
									: this.renderConfirmationForm()}
								<div className="text-center">
									<a className="d-block small mt-3" href="/">
										Log in
									</a>
								</div>
							</CardBody>
						</Card>
					</Container>
				</BodyClassName>
			</DocumentTitle>
		);
	}
}
