import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

const BASE_PATH = {
	cu: {
		path: '/home',
		title: 'Copper Dashboard'
	},
	ni: {
		path: '/ni/home',
		title: 'Nickel Dashboard'
	}
};

class Crumbs extends Component {
	static propTypes = {
		location: PropTypes.object.isRequired
	};

	render() {
		const { type = 'cu' } = this.props;

		return (
			<ol className="breadcrumb py-2">
				<li
					className={
						'breadcrumb-item' +
						(this.props.location.pathname === BASE_PATH[type].path
							? ' active'
							: '')
					}
				>
					<NavLink exact to={BASE_PATH[type].path}>
						{BASE_PATH[type].title}
					</NavLink>
				</li>
				{this.props.path &&
					this.props.path.map((crumbItem, i) => {
						return (
							<li
								key={i}
								className={
									'breadcrumb-item' +
									(this.props.location.pathname === crumbItem.path
										? ' active'
										: '')
								}
							>
								{crumbItem.path && (
									<NavLink exact to={crumbItem.path}>
										{crumbItem.title}
									</NavLink>
								)}
								{!crumbItem.path && crumbItem.title}
							</li>
						);
					})}
			</ol>
		);
	}
}

export default withRouter(Crumbs);
