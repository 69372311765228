import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
	Badge,
	Button,
	ButtonGroup,
	ButtonToggle,
	Collapse,
	Form,
	Navbar,
	NavbarToggler,
	NavbarBrand,
	Nav,
	NavItem,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	UncontrolledDropdown
} from 'reactstrap';
import { Link, NavLink } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import BodyClassName from 'react-body-classname';

import ReactSVG from 'react-svg';
import ReactGA from 'react-ga';

import packageJson from '../../package.json';

import { Flag } from 'flag';
import menuConfig from './menuConfig';
import { TemplateUI } from 'components';
import {
	getAuthenticatedUserAttributes,
	getAuthenticatedUserGroups,
	fetchSmelterRegions,
	fetchNiSmelterRegions
} from '../actions';

import {
	ApiModal,
	ApiCredentialsModal,
	ContactModal,
	PasswordModal,
	MySmeltersModal
} from '../components/modals';
import {
	getSecuredGroups,
	getSalesOrEnterpriseTypes,
	isUserEnterprise
} from '../utils';

import savantCuLogo from 'images/savant-cu-white.svg';
import savantNiLogo from 'images/savant-ni-white.svg';

import './Navigation.scss';

const securedElementGroups = getSecuredGroups();

class Navigation extends Component {
	constructor(props) {
		super(props);

		this.state = {
			authGroups: [],
			isEnterprise: false,
			showSecuredElements: false,
			showPasswordModal: false,
			showContactModal: false,
			showApiModal: false,
			showApiCredModal: false,
			showMySmeltersModal: false,
			collapsed: true
		};

		this.togglePasswordModal = this.toggle.bind(this, 'showPasswordModal');
		this.toggleContactModal = this.toggle.bind(this, 'showContactModal');
		this.toggleApiModal = this.toggle.bind(this, 'showApiModal');
		this.toggleApiCredModal = this.toggle.bind(this, 'showApiCredModal');
		this.toggleMySmeltersModal = this.toggle.bind(this, 'showMySmeltersModal');
	}

	async componentDidMount() {
		const userAttributes = await getAuthenticatedUserAttributes();
		const { authenticatedUserGroups: authGroups } =
			await getAuthenticatedUserGroups();

		this.setState({
			...userAttributes,
			authGroups,
			showSecuredElements:
				securedElementGroups.length === 0 ||
				securedElementGroups.some((r) => authGroups.includes(r)),
			isEnterprise: isUserEnterprise(authGroups)
		});
		const types = getSalesOrEnterpriseTypes(authGroups);
		if (types.includes('cu')) {
			this.props.fetchSmelterRegions();
		}
		if (types.includes('ni')) {
			this.props.fetchNiSmelterRegions();
		}
	}

	handleLogout = async () => {
		await Auth.signOut();
		ReactGA.event({
			category: 'authorisation',
			action: 'logout',
			label: this.state.username
		});
		this.props.userHasAuthenticated(false, []);
		this.props.history.push('/');
	};

	handleMenuClose = () => {
		if (!this.state.collapsed) {
			this.toggle('collapsed');
		}
	};

	toggle(toggler) {
		this.setState({
			[toggler]: !this.state[toggler]
		});
	}

	generateTypePicker({ type }) {
		return (
			<Form inline>
				<ButtonGroup className="mr-3">
					<Button
						tag={Link}
						to="/home"
						id="typeCopper"
						size="sm"
						className="copper"
					>
						{type === 'cu' ? (
							<i className="fa fa-circle mr-2" aria-hidden="true" />
						) : (
							<i className="fa fa-circle-thin mr-2" aria-hidden="true" />
						)}
						Copper
					</Button>
					<Button
						tag={Link}
						to="/ni/home"
						id="typeNickel"
						size="sm"
						className="nickel"
					>
						{type === 'ni' ? (
							<i className="fa fa-circle mr-2" aria-hidden="true" />
						) : (
							<i className="fa fa-circle-thin mr-2" aria-hidden="true" />
						)}
						Nickel
					</Button>
				</ButtonGroup>
			</Form>
		);
	}

	generateAccountMenu() {
		const { isEnterprise, showSecuredElements, initials, email } = this.state;
		return (
			<UncontrolledDropdown nav inNavbar>
				<DropdownToggle
					id="navbarAccountDropdownMenuLink"
					tag="button"
					type="button"
					data-toggle="dropdown"
					className="btn btn-badge"
				>
					{initials} <i className="fa fa-angle-down ml-2" />
				</DropdownToggle>
				<DropdownMenu right aria-labelledby="navbarAccountDropdownMenuLink">
					<DropdownItem header>{email}</DropdownItem>
					{showSecuredElements && (
						<Fragment>
							<DropdownItem divider />
							<DropdownItem onClick={this.toggleApiModal}>
								<i className="fa fa-fw fa-info mr-1" /> 3rd-party API Access
							</DropdownItem>
							<DropdownItem onClick={this.toggleApiCredModal}>
								<i className="fa fa-fw fa-shield mr-1" /> API Credentials
							</DropdownItem>
						</Fragment>
					)}
					{isEnterprise && (
						<DropdownItem onClick={this.toggleMySmeltersModal}>
							<i className="fa fa-fw fa-fire mr-1" /> My Smelters
						</DropdownItem>
					)}
					<DropdownItem divider />
					<DropdownItem onClick={this.toggleContactModal}>
						<i className="fa fa-fw fa-at mr-1" /> Contact Earth-i
					</DropdownItem>
					<DropdownItem divider />
					<DropdownItem onClick={this.togglePasswordModal}>
						<i className="fa fa-fw fa-lock mr-1" /> Password Change
					</DropdownItem>
					<DropdownItem onClick={this.handleLogout}>
						<i className="fa fa-fw fa-sign-out mr-1" /> Logout
					</DropdownItem>
					<DropdownItem divider />
					<DropdownItem header className="smaller text-center p-0">
						App version: {packageJson.version}
					</DropdownItem>
				</DropdownMenu>
			</UncontrolledDropdown>
		);
	}

	renderMenuItem(menuItem) {
		const showLink =
			menuItem.groups.length === 0 ||
			menuItem.groups.some((r) => this.state.authGroups.includes(r));

		const item = (
			<span className="nav-link-text">
				{menuItem.title}
				{menuItem.groups.length > 0 && menuItem.version !== 'beta' && (
					<sup>
						<Badge color="primary" className="ml-1">
							PRO
						</Badge>
					</sup>
				)}
				{menuItem.version === 'beta' && (
					<sup>
						<Badge color="danger" className="ml-1">
							BETA
						</Badge>
					</sup>
				)}
			</span>
		);
		if (showLink) {
			return (
				<NavLink
					exact
					className="nav-link position-relative"
					to={menuItem.path}
				>
					<i className={'fa fa-fw mr-2 ' + menuItem.icon} />
					{item}
				</NavLink>
			);
		}
		return (
			<span className="nav-link disabled position-relative">
				<i className={'fa fa-fw mr-2 ' + menuItem.icon} />
				{item}
			</span>
		);
	}

	generateMenuItems({ type }) {
		const {
			location: { pathname }
		} = this.props;

		return menuConfig[type].map((menuItem, i) => {
			const activeLink =
				pathname === menuItem.path ||
				pathname.substring(0, pathname.lastIndexOf('/')) === menuItem.path;
			return (
				<NavItem
					key={i}
					active={activeLink}
					data-toggle="tooltip"
					data-placement="right"
					title={menuItem.title}
					onClick={this.handleMenuClose}
				>
					{this.renderMenuItem(menuItem)}
				</NavItem>
			);
		});
	}

	render() {
		const { type = 'cu' } = this.props;
		const {
			isEnterprise,
			collapsed,
			showApiCredModal,
			authToken,
			showApiModal,
			showContactModal,
			showPasswordModal,
			showMySmeltersModal
		} = this.state;

		let logoSrc = savantCuLogo;
		let homeLink = '/home';
		if (type === 'ni') {
			logoSrc = savantNiLogo;
			homeLink = '/ni/home';
		}

		return (
			<Fragment>
				<BodyClassName className={`fixed-nav sticky-footer bg-dark ${type}_bg`}>
					<Navbar id="mainNav" color="dark" dark expand="lg" fixed="top">
						<NavbarBrand tag={Link} to={homeLink} aria-current="page">
							<ReactSVG src={logoSrc} svgClassName="savant-logo" />
							<h1 className="sr-only">SAVANT</h1>
						</NavbarBrand>
						<Flag
							name="features.isPreview"
							render={() => (
								<span className="savant-logo-preview font-italic font-weight-bold">
									Preview
								</span>
							)}
						/>

						<NavbarToggler onClick={() => this.toggle('collapsed')} />
						<Collapse isOpen={!collapsed} navbar>
							<Nav className="ml-auto" navbar>
								<NavItem>
									<Nav vertical navbar className="navbar-sidenav">
										{this.generateMenuItems({ type })}
									</Nav>
								</NavItem>

								<Nav className="sidenav-toggler">
									<NavItem>
										<ButtonToggle
											id="sidenavToggler"
											className="nav-link text-center"
											color="secondary"
											style={{ boxShadow: 'none' }}
										>
											<i className="fa fa-fw fa-angle-left" />
											<span className="sr-only">Toggle sidenav</span>
										</ButtonToggle>
									</NavItem>
								</Nav>
								{this.generateTypePicker({ type })}
								{this.generateAccountMenu()}
							</Nav>
							<ApiCredentialsModal
								open={showApiCredModal}
								toggle={this.toggleApiCredModal}
								token={showApiCredModal ? authToken : ''}
							/>
							<ApiModal open={showApiModal} toggle={this.toggleApiModal} />
							{isEnterprise && (
								<MySmeltersModal
									open={showMySmeltersModal}
									toggle={this.toggleMySmeltersModal}
								/>
							)}
							<ContactModal
								open={showContactModal}
								toggle={this.toggleContactModal}
							/>
							<PasswordModal
								open={showPasswordModal}
								toggle={this.togglePasswordModal}
							/>
						</Collapse>
					</Navbar>
				</BodyClassName>
				<TemplateUI />
			</Fragment>
		);
	}
}

const mapDispatchToProps = (dispatch) => ({
	fetchSmelterRegions: () => dispatch(fetchSmelterRegions()),
	fetchNiSmelterRegions: () => dispatch(fetchNiSmelterRegions())
});

export default connect(null, mapDispatchToProps)(Navigation);
