import {
	getGroupsPerType,
	getGroupsPremiumHigher,
	getGroupsSalesEnterprise
} from '../utils';
const menuConfig = {
	cu: [
		{
			path: '/home',
			title: 'Copper Dashboard',
			icon: 'fa-dashboard',
			groups: []
		},
		{
			path: '/smelterInfo',
			title: 'Smelter Process',
			icon: 'fa-flask',
			groups: getGroupsPerType('cu')
		},
		{
			path: '/smelters',
			title: 'Smelter Operations',
			icon: 'fa-fire',
			groups: getGroupsPerType('cu')
		},
		{
			path: '/smeltersMap',
			title: 'Smelter Map',
			icon: 'fa-map-o',
			groups: getGroupsSalesEnterprise('cu')
		},
		{
			path: '/smeltersCapacity',
			title: 'Smelter Capacity',
			icon: 'fa-line-chart',
			groups: getGroupsPremiumHigher('cu'),
			// version: 'beta'
		},
		{
			path: '/smeltersProduction',
			title: 'Production',
			icon: 'fa-bar-chart',
			groups: getGroupsPremiumHigher('cu'),
			version: 'beta'
		}
	],
	ni: [
		{
			path: '/ni/home',
			title: 'Nickel Dashboard',
			icon: 'fa-dashboard',
			groups: []
		},
		{
			path: '/ni/smelterInfo',
			title: 'Smelter Process',
			icon: 'fa-flask',
			groups: getGroupsPerType('ni')
		},
		{
			path: '/ni/smelters',
			title: 'Smelter Operations',
			icon: 'fa-fire',
			groups: getGroupsPerType('ni')
		},
		{
			path: '/ni/smeltersMap',
			title: 'Smelter Map',
			icon: 'fa-map-o',
			groups: getGroupsSalesEnterprise('ni')
		},
		{
			path: '/ni/smeltersCapacity',
			title: 'Smelter Capacity',
			icon: 'fa-line-chart',
			groups: getGroupsPremiumHigher('ni'),
			// version: 'beta'
		},
		{
			path: '/ni/smeltersProduction',
			title: 'Production',
			icon: 'fa-bar-chart',
			groups: getGroupsPremiumHigher('ni'),
			version: 'beta'
		}
	]
};

export default menuConfig;
